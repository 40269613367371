import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { take } from 'rxjs';
import { MaterialModule } from 'src/app/material.module';
import { DashboardPageService } from 'src/app/services/dashboard-page.service';

@Component({
  selector: 'app-send-feedback',
  standalone: true,
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule
  ],
  templateUrl: './send-feedback.component.html',
  styleUrls: ['./send-feedback.component.scss'],
})
export class SendFeedbackComponent  implements OnInit {

  text: string = "Tell us how we're doing! Please share your thoughts, suggestions, or concerns to help us improve our software.";
  message: string = '';

  loading: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<SendFeedbackComponent>,
    private dashboardService: DashboardPageService,
    private _snackBar: MatSnackBar,
  ) { }

  ngOnInit() {}

  cancel(event?: string) {
    this.dialogRef.close({ event: event || 'cancel'});
  }

  sendFeedback(message: string) {
    this.loading = true;

    console.log(message)
    const dataToSend = {
      message
    };

    this.dashboardService.sendFeedback(dataToSend).pipe(take(1)).subscribe({
      next: res => {
        this.loading = false;
        const msg = 'Feedback sent.';
        this._snackBar.open(msg, 'Close', { horizontalPosition: 'center', verticalPosition: 'top',  duration: 3000, panelClass: 'customSnackBar' });
        this.cancel('add');
      },
      error: err => {
        this.loading = false;
        const msg = err.error.error;
        this._snackBar.open(msg, 'Close', { horizontalPosition: 'center', verticalPosition: 'top',  duration: 3000, panelClass: 'customSnackBar' });
      }
    });
  }

}
