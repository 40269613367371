<h2 mat-dialog-title class="m-b-20">Send Feedback</h2>
<mat-divider></mat-divider>

<mat-dialog-content class="mat-typography" style="max-width: 600px">

  <p>
    {{text}}
  </p>

  <mat-form-field appearance="outline" class="w-100">
    <mat-label>Message</mat-label>
    <textarea
      [(ngModel)]="message"
      rows="5"
      matInput
      placeholder="Message"
    ></textarea>
  </mat-form-field>

</mat-dialog-content>

<mat-divider></mat-divider>
<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
<mat-dialog-actions class="p-24">
  <button mat-stroked-button color="warn" (click)="cancel()">Cancel</button>
  <button
    color="accent"
    mat-flat-button
    [disabled]="message == '' || loading"
    (click)="sendFeedback(message)"
  >
  Send
  </button>
</mat-dialog-actions>
